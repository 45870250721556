<template>
  <div class="evaluation-system-container">
    <div class="top-container">
      <a-row>
        <a-form-model layout="inline" style="display: flex">
          <a-form-model-item label="年度">
            <a-input
              placeholder="请输入"
              v-model.trim="queryFrom.annual"
              @keyup.enter="queryBtn"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-select
              show-search
              placeholder="请选择"
              :getPopupContainer="
                (triggerNode) => handleDislocation(triggerNode)
              "
              :default-active-first-option="false"
              :style="{ width: '28.5rem' }"
              :not-found-content="null"
              v-model="queryFrom.state"
            >
              <a-select-option
                v-for="(item, i) in stateList"
                :value="item.value"
                :key="i"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <div class="right">
            <a-button type="reload" icon="redo" @click="handleReset"
              >重置</a-button
            >
            <a-button type="primary" icon="search" @click="queryBtn"
              >查询</a-button
            >
          </div>
        </a-form-model>
      </a-row>
    </div>
    <div class="bottom-container">
      <a-button class="mb2" type="primary" @click="handleDraft('起草')"
        >起草</a-button
      >
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :scroll="{ y: 415 }"
        :pagination="pagination"
      >
        <template slot="state" slot-scope="text">
          <span
            :style="{ color: `${getColor(getMenuName(stateList, text))}` }"
            >{{ getMenuName(stateList, text) }}</span
          >
        </template>
        <template slot="operation" slot-scope="text, record">
          <span
            class="hover"
            v-if="record.state === 0"
            :style="{ color: `${getColor('主题')}` }"
            @click="openModal(record.id, '修改')"
            >修改</span
          >
          <span
            class="hover"
            v-if="record.state === 0"
            :style="{ color: `${getColor('主题')}` }"
            @click="handleDelete(record)"
            >删除</span
          >
          <span
            class="hover"
            v-if="record.state === 0"
            :style="{ color: `${getColor('主题')}` }"
            @click="handleEnable(record.id, record)"
            >启用</span
          >
          <span
            class="hover"
            v-if="record.state === 1"
            :style="{ color: `${getColor('主题')}` }"
            @click="handleDisabled(record.id, record)"
            >停用</span
          >
          <span
            class="hover"
            v-if="record.state === 1 || record.state === 2"
            :style="{ color: `${getColor('主题')}` }"
            @click="openModal(record.id, '查看')"
            >查看</span
          >
          <span
            class="hover"
            v-if="record.state === 1"
            :style="{ color: `${getColor('主题')}` }"
            @click="handleFile(record.id, record)"
            >归档</span
          >
          <span
            class="hover"
            v-if="record.state === 2"
            :style="{ color: `${getColor('主题')}` }"
            @click="handleCopy(record.id)"
            >复制</span
          >
          <span
            class="hover"
            :style="{ color: `${getColor('主题')}` }"
            @click="handleImport(record.id, record)"
            >导出</span
          >
        </template>
      </a-table>
    </div>

    <AnnualModal
      v-if="annualModal"
      :annualModal="annualModal"
      @handleChangeAnnual="handleChangeAnnual"
    />
    <EvSystemModal
      v-if="evSystemModal"
      :evSystemModal="evSystemModal"
      :config="configFrom"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "年度",
    ellipsis: true,
    dataIndex: "annual",
    scopedSlots: { customRender: "annual" },
  },
  {
    title: "状态",
    ellipsis: true,
    dataIndex: "state",
    scopedSlots: { customRender: "state" },
  },
  {
    title: "操作",
    ellipsis: true,
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { getColor } from "@/utils/color";
import { getMenuName } from "@/utils/utils.js";
import AnnualModal from "./annualModal.vue";
import EvSystemModal from "./evSystemModal.vue";
import { API } from "@/apix/evaluationSystem.js";
import { tips } from "@/utils/tips.js";
export default {
  mixins: [pagination, mixins],
  components: {
    AnnualModal,
    EvSystemModal,
  },
  data() {
    return {
      columns,
      data: [
        // {
        //   annual: "2020",
        //   state: 0,
        // },
        // {
        //   annual: "2021",
        //   state: 1,
        // },
        // {
        //   annual: "2022",
        //   state: 2,
        // },
      ],
      loading: false,
      queryFrom: {
        annual: null, // 年度
        state: undefined, // 状态
      },
      stateList: [
        { name: "草稿", value: 0 },
        { name: "生效", value: 1 },
        { name: "封存", value: 2 },
      ],
      annualModal: false, // 年度弹出框显示
      evSystemModal: false, // 评价体系弹出框
      configFrom: {
        type: null,
        id: null,
        annual: null, // 后端起草表格头部的时候需要年份
      },
      copyId: null, // 复制暂存id
    };
  },
  methods: {
    getColor,
    getMenuName,
    getSourceData() {
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
      };
      this.loading = true;
      API.pageIndex(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },

    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      Object.keys(this.queryFrom).forEach((key) => {
        this.queryFrom[key] = null;
      });
      this.queryFrom.state = undefined;
      this.getSourceData();
    },

    // 删除
    handleDelete(record) {
      if (this.data.length < 2) {
        this.pagination.current--;
      }
      let self = this;
      this.$confirm({
        title: "是否确定删除数据?",
        content: "删除后数据无法恢复",
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.deleteEvaluation(record.id).then((res) => {
            tips(res, "删除");
            if (res.success) {
              self.getSourceData();
            }
            self.loading = false;
          });
        },
      });
    },

    // 启用
    handleEnable(id, record) {
      let self = this;
      this.$confirm({
        content: `是否确认启用 “${record.annual}年度工厂评价体系"?`,
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.evaluationEnable(id).then((res) => {
            tips(res, "启用");
            if (res.success) {
              self.getSourceData();
            }
            self.loading = false;
          });
        },
      });
    },

    // 停用
    handleDisabled(id, record) {
      let self = this;
      this.$confirm({
        content: `是否确认停用 “${record.annual}年度工厂评价体系"?`,
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.evaluationDisabled(id).then((res) => {
            tips(res, "停用");
            if (res.success) {
              self.getSourceData();
            }
            self.loading = false;
          });
        },
      });
    },

    //归档
    handleFile(id, record) {
      let self = this;
      this.$confirm({
        content: `是否确认归档 “${record.annual}年度工厂评价体系"?`,
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.evaluationFile(id).then((res) => {
            tips(res, "归档");
            if (res.success) {
              self.getSourceData();
            }
            self.loading = false;
          });
        },
      });
    },

    // 复制
    handleCopy(id) {
      this.copyId = id;
      this.configFrom.type = "复制";
      this.annualModal = true;
    },

    // 处理年份弹出框选择完年份之后的操作
    handleChangeAnnual(val) {
      if (this.configFrom.type === "复制") {
        let detail = {
          id: this.copyId,
          annual: val,
        };
        API.evaluationCopy(detail).then((res) => {
          tips(res, "复制");
          if (res.success) {
            this.getSourceData();
          }
        });
      } else {
        this.evSystemModal = true;
      }
      this.configFrom.annual = val;
    },

    // 数据导出
    handleImport(id) {
      API.evaluationImport(id).then((res) => {
        const link = document.createElement("a");
        //对文件名乱码转义--【Node.js】使用iconv-lite解决中文乱码;

        let blob = new Blob([res.data], {
          type: "application/pdf;chartset=UTF-8",
        });
        let s = res.headers["content-disposition"];
        let x = s.slice(s.indexOf("=") + 1);
        let fileName = decodeURI(decodeURIComponent(escape(x)), "UTF-8");
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.exportLoading = false;
      });
    },

    // 处理起草评价
    handleDraft(type) {
      this.annualModal = true;
      this.configFrom.type = type;
    },
    openModal(id, type) {
      this.configFrom.id = id;
      this.configFrom.type = type;
      this.evSystemModal = true;
    },
    closeModal() {
      this.annualModal = false;
      this.evSystemModal = false;
    },
  },
  created() {
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.top-container {
  height: 9rem;
}
.bottom-container {
  min-height: 83%;
}
.hover {
  margin-right: 2rem;
}
.ant-input {
  width: 28.5rem;
}
</style>
