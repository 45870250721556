<template>
  <div class="annual-modal-container">
    <a-modal
      :visible="annualModal"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      :maskClosable="false"
      title="年份"
      :width="533"
    >
      <a-form-model layout="inline">
        <a-form-model-item label="年份">
          <a-select
            show-search
            placeholder="请选择"
            :getPopupContainer="(triggerNode) => handleDislocation(triggerNode)"
            :default-active-first-option="false"
            :style="{ width: '28.5rem' }"
            :not-found-content="null"
            v-model="annual"
          >
            <a-select-option
              v-for="(item, i) in annualList"
              :value="item"
              :key="i"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins.js";
import { API } from "@/apix/evaluationSystem.js";
export default {
  mixins: [mixins],
  props: {
    annualModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      annual: null, // 年份
      annualList: [], // 年份可选项（未来五年）
    };
  },
  methods: {
    handleOk() {
      if (this.annual === null) {
        this.$message.error("请选择一个年份");
      } else {
        API.checkAnnual(this.annual).then((res) => {
          if (res.data) {
            this.cancel();
            this.$emit("handleChangeAnnual", this.annual);
          } else {
            this.$message.error("已存在该年度的评价体系");
          }
        });
      }
    },
    // 拿取未来五年年份数据
    getFiveYear(nowYear) {
      this.annualList.push(nowYear);
      for (let i = 1; i <= 5; i++) {
        nowYear = nowYear + 1;
        this.annualList.push(nowYear);
      }
    },
  },
  created() {
    let nowYear = new Date().getFullYear();
    this.annual = nowYear;
    this.getFiveYear(nowYear);
  },
};
</script>

<style lang="scss" scoped>
.ant-input {
  width: 28.5rem;
}
</style>
