import axios from "@/config/http.js";

// 评价体系
export const API = {
  // 评价体系首页
  async pageIndex(params) {
    return await axios.get("/evaluateSystem/systemList", { params });
  },
  // 评价体系详情数据
  async detailData(id) {
    return await axios.get(`evaluateSystem/system/${id}`);
  },
  // 评价体系删除
  async deleteEvaluation(id) {
    return await axios.delete(`evaluateSystem/system/${id}`);
  },
  // 评价体系明细删除
  async deleteEvaluationDetail(normId) {
    return await axios.delete(`/evaluateSystem/systemDetail/${normId}`);
  },
  // 评价体系添加（点击起草，后端生成表格头部数据）
  async addEvaluationHeader(annual) {
    return await axios.post(`/evaluateSystem/systemAdd/${annual}`);
  },
  // 评价体系明细添加（添加同级，添加下级）
  async addEvaluationDetail(params) {
    return await axios.post("/evaluateSystem/systemDetailAdd", params);
  },
  // 评价体系明细添加（添加同级，添加下级）
  async editEvaluationDetail(params) {
    return await axios.post("/evaluateSystem/systemDetailUpdate", params);
  },
  // 评价体系禁用（停用按钮）
  async evaluationDisabled(id) {
    return await axios.put(`/evaluateSystem/systemEnd/${id}`);
  },
  // 评价体系归档（归档按钮）
  async evaluationFile(id) {
    return await axios.put(`/evaluateSystem/systemFile/${id}`);
  },
  // 评价体系启用（启用按钮）
  async evaluationEnable(id) {
    return await axios.put(`/evaluateSystem/systemStart/${id}`);
  },
  // 评价体系明细修改 （表格节点编辑提交）
  async evaluationDetailEdit(params) {
    return await axios.post("/evaluateSystem/systemDetailUpdate", params);
  },
  // 评价体系明细详情（表格节点编辑回填数据）
  async evaluationDetail(normId) {
    return await axios.get(`/evaluateSystem/systemDetail/${normId}`);
  },
  // 评价体系复制（复制按钮）
  async evaluationCopy(params) {
    return await axios.get("/evaluateSystem/systemCopy", { params });
  },

  // 评价体系导出
  async evaluationImport(id) {
    return await axios.get(`/evaluateSystem/Export/${id}`, {
      responseType: "arraybuffer",
    });
  },

  // 工厂评价体系年份校验
  async checkAnnual(annual) {
    return await axios.put(`/evaluateSystem/systemAnnual/${annual}`);
  },
};
