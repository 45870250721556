<template>
  <div class="draft-modal-container">
    <a-modal
      :visible="draftModal"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      :maskClosable="false"
      :width="1200"
    >
      <template slot="title">
        <span class="gray" v-if="config.type === 'edit'">编辑</span>
        <span class="gray" v-else>起草</span>
      </template>
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="submintFrom"
        :rules="rules"
      >
        <div class="layout-inline mt2 mb1">
          <a-form-model-item label="所属指标" class="center starDisplay">
            <a-input v-model.trim="submintFrom.parentName" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="指标名称" class="center" prop="normName">
            <a-input v-model.trim="submintFrom.normName"></a-input>
          </a-form-model-item>
        </div>
        <div class="layout-inline mb1">
          <a-form-model-item label="指标分类" class="center" prop="normType">
            <a-select
              show-search
              placeholder="请选择"
              :disabled="markVisible"
              :getPopupContainer="
                (triggerNode) => handleDislocation(triggerNode)
              "
              :default-active-first-option="false"
              :style="{ width: '28.5rem' }"
              :not-found-content="null"
              v-model="submintFrom.normType"
            >
              <a-select-option
                v-for="(item, i) in normTypeList"
                :value="item.value"
                :key="i"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="指标满分"
            v-if="submintFrom.normType === 1"
            class="center"
            prop="normFullmark"
          >
            <a-input-number
              style="width: 28.5rem"
              v-model.trim="submintFrom.normFullmark"
              :min="0.1"
              :precision="1"
            ></a-input-number>
          </a-form-model-item>
        </div>
        <div class="layout-inline mb1">
          <a-form-model-item
            label="打分方式"
            v-if="submintFrom.normType === 1"
            class="center"
            prop="markType"
          >
            <a-select
              show-search
              placeholder="请选择"
              :getPopupContainer="
                (triggerNode) => handleDislocation(triggerNode)
              "
              :default-active-first-option="false"
              :style="{ width: '28.5rem' }"
              :not-found-content="null"
              v-model="submintFrom.markType"
            >
              <a-select-option
                v-for="(item, i) in markTypeList"
                :value="item.value"
                :key="i"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="选择项"
            v-if="submintFrom.normType === 1 && submintFrom.markType === 0"
            prop="selection"
          >
            <span v-for="(tag, index) in tags" :key="tag">
              <a-tag :closable="index !== -1" @close="() => handleClose(tag)">
                {{ tag }}
              </a-tag>
            </span>
            <a-input-number
              v-if="inputVisible"
              ref="input"
              type="number"
              size="small"
              :style="{ width: '78px' }"
              :min="0"
              :precision="1"
              @change="handleInputChange"
              @blur="handleInputConfirm"
              @keyup.enter="handleInputConfirm"
            />
            <a-tag
              v-else
              style="background: #fff; borderstyle: dashed"
              @click="showInput"
            >
              <a-icon type="plus" />
            </a-tag>
          </a-form-model-item>
        </div>
        <a-form-model-item
          label="校验标准"
          v-if="submintFrom.normType === 1"
          prop="inspectionStandard"
        >
          <a-textarea
            style="width: 86.7rem"
            :auto-size="{ minRows: 4 }"
            v-model.trim="submintFrom.inspectionStandard"
            :maxLength="256"
          ></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins.js";
import { API } from "@/apix/evaluationSystem.js";
import { tips } from "@/utils/tips.js";
import { evaluationRule } from "@/utils/rulex.js";
export default {
  mixins: [mixins],
  props: {
    draftModal: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rules: evaluationRule,
      // 标签
      tags: [],
      inputVisible: false,
      inputValue: "",
      submintFrom: {
        evaluateId: null, // 评价表id
        parentId: null, // 所属指标id
        parentName: null, // 所属指标名称
        normType: null, // 指标分类
        normFullmark: null, // 指标满分
        markType: null, // 打分方式
        normName: null, // 指标名称
        inspectionStandard: null, // 校验标准
        selection: null, // 选择项
      },
      // 打分类型
      normTypeList: [
        { name: "不打分", value: 0 },
        { name: "打分", value: 1 },
      ],
      // 打分方式
      markTypeList: [
        { name: "分档选择", value: 0 },
        { name: "人工打分", value: 1 },
      ],
      markVisible: false, // 打分输入框禁用
    };
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.config.type === "edit") {
            this.evaluationDetailEdit();
          } else {
            this.addEvaluationDetail();
          }
        }
      });
    },

    // 处理标签方法（关闭标签）
    handleClose(removedTag) {
      const tags = this.tags.filter((tag) => tag !== removedTag);
      this.tags = tags;
      this.handleInputConfirm();
    },
    // 展示输入框
    showInput() {
      this.inputVisible = true;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },
    // 输入框改变
    handleInputChange(val) {
      this.inputValue = String(val);
    },
    handleInputConfirm() {
      const inputValue = this.inputValue;
      let tags = this.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      if (tags.includes("null")) {
        this.$message.error("选择项中不能null选择");
      } else {
        this.submintFrom.selection = tags.toString();
        Object.assign(this, {
          tags,
          inputVisible: false,
          inputValue: "",
        });
      }
    },
    // 添加评价体系（同级，下级）
    addEvaluationDetail() {
      API.addEvaluationDetail(this.submintFrom).then((res) => {
        tips(res, "添加");
        if (res.success) {
          this.cancel();
          this.$parent.getDetailData(this.config.record.evaluateId);
        }
      });
    },
    // 编辑评价体系（同级，下级）
    evaluationDetailEdit() {
      this.submintFrom.normId = this.config.record.id;
      API.editEvaluationDetail(this.submintFrom).then((res) => {
        tips(res, "编辑");
        if (res.success) {
          this.cancel();
          this.$parent.getDetailData(this.config.record.evaluateId);
        }
      });
    },
    // 获取工厂评价明细详情
    evaluationDetail(normId) {
      API.evaluationDetail(normId).then((res) => {
        if (res.success) {
          for (const key in this.submintFrom) {
            this.submintFrom[key] = res.data[key];
            this.tags = res.data.selection ? res.data.selection.split(",") : [];
          }
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },

    // 回填id和指标
    backFillData() {
      this.submintFrom.evaluateId = this.config.record.evaluateId;
      if (this.config.record.parentId === null) {
        this.submintFrom.parentName =
          this.config.record.label + "年工厂评价体系";
      } else {
        this.submintFrom.parentName = this.config.record.parentName;
      }

      if (this.config.level === "下级") {
        this.submintFrom.parentId = this.config.record.id;
        this.submintFrom.parentName = this.config.record.label;
      } else {
        this.submintFrom.parentId = this.config.record.parentId;
      }

      if (this.config.type === "edit") {
        this.markVisible = true;
        this.evaluationDetail(this.config.record.id);
      }
    },
  },
  created() {
    this.backFillData();
  },
};
</script>

<style lang="scss" scoped>
.layout-inline {
  width: 100%;
  display: flex;
  // justify-content: space-around;
  &.wrap {
    flex-wrap: wrap;
  }
}
.gray {
  color: rgba(0, 0, 0, 0.45);
  font-size: 18px;
}
.black {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
}
.ant-input {
  width: 28.5rem;
}
.center {
  width: 50%;
}
.mt2 {
  margin-top: 2rem;
}
.mb1 {
  margin-bottom: 1rem;
}
.starDisplay::before {
  display: inline-block;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 2.5;
  opacity: 0;
  content: "*";
  margin-left: 0.5rem;
}
</style>
