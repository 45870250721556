<template>
  <div class="ev-system-modal-container">
    <a-modal
      :visible="evSystemModal"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      okText="启用"
      :maskClosable="false"
      :title="config.type === '修改' ? '编辑' : '起草'"
      :width="1200"
    >
      <div class="evaluationtitle">工厂评价体系</div>
      <a-table
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :key="data.key"
        :scroll="{ y: 400 }"
        :pagination="false"
        :defaultExpandedRowKeys="keysArr"
      >
        <template slot="operation" slot-scope="text, record">
          <a-tooltip>
            <template slot="title"> 新增下级节点 </template>
            <a-icon
              class="fs"
              type="plus-circle"
              v-if="record.normType === 0"
              @click="handleSubLevel(record, 'add')"
            />
          </a-tooltip>
          <a-tooltip>
            <template slot="title"> 新增同级节点 </template>
            <a-icon
              class="fs"
              type="plus-square"
              v-if="record.visibleIcon !== 1"
              @click="handleSameLevel(record, 'add')"
            />
          </a-tooltip>
          <a-icon
            class="fs"
            type="form"
            v-if="record.visibleIcon !== 1"
            @click="openModal(record, 'edit')"
          />
          <a-icon
            class="fs"
            type="delete"
            v-if="record.visibleIcon !== 1"
            @click="handleDelete(record, record.id)"
          />
        </template>

        <template slot="inspectionStandard" slot-scope="text">
          <div style="white-space: pre-line">{{ text }}</div>
        </template>
      </a-table>
    </a-modal>

    <!-- 新增评价内容 -->
    <DraftModal
      v-if="draftModal"
      :draftModal="draftModal"
      :config="configFrom"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "评价内容",
    ellipsis: true,
    dataIndex: "label",
    width: "30%",
  },
  {
    title: "分数",
    ellipsis: true,
    dataIndex: "normFullmark",
    width: "10%",
  },
  {
    title: "校验标准",
    ellipsis: true,
    dataIndex: "inspectionStandard",
    width: "40%",
    scopedSlots: { customRender: "inspectionStandard" },
  },
  {
    title: "操作",
    ellipsis: true,
    scopedSlots: { customRender: "operation" },
    width: "20%",
  },
];
import mixins from "@/mixins/mixins.js";
import DraftModal from "./draftModal.vue";
import { API } from "@/apix/evaluationSystem.js";
import { tips } from "@/utils/tips.js";
export default {
  mixins: [mixins],
  props: {
    evSystemModal: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DraftModal,
  },
  data() {
    return {
      columns,
      data: [],
      loading: false,
      draftModal: false,
      configFrom: {
        record: null, // 添加同级下级所需要的数据
        type: null,
        level: null, // 级别
      },
      draftId: null, // 起草直接启用所需id
      keysArr: [], // 踩坑antdv表格默认展开所有行
    };
  },
  methods: {
    handleOk() {
      // 启用
      API.evaluationEnable(this.draftId).then((res) => {
        tips(res, "启用");
        if (res.success) {
          this.$parent.getSourceData();
          this.cancel();
        }
      });
    },
    closeModal() {
      this.draftModal = false;
      this.$parent.getSourceData();
    },
    // 处理新增下级节点
    handleSubLevel(record, type) {
      this.draftModal = true;
      this.configFrom.type = type;
      this.configFrom.record = record;
      this.configFrom.level = "下级";
    },
    // 处理新增同级节点
    handleSameLevel(record, type) {
      this.draftModal = true;
      this.configFrom.type = type;
      this.configFrom.record = record;
      this.configFrom.level = "同级";
    },
    // 删除节点
    handleDelete(record, id) {
      let self = this;
      this.$confirm({
        title: "是否确定删除该数据?",
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.deleteEvaluationDetail(id).then((res) => {
            tips(res, "删除");
            if (res.success) {
              self.getDetailData(record.evaluateId);
            }
            self.loading = false;
          });
        },
      });
    },
    openModal(record, type) {
      this.draftModal = true;
      this.configFrom.record = record;
      this.configFrom.type = type;
    },
    getNewData(arr) {
      arr.map((item) => {
        item.key = item.id;
        this.keysArr.push(item.key);
        if (item.children) {
          this.getNewData(item.children);
        }
        return arr;
      });
    },
    // 起草标准时拿去后端生成的表格头
    evaluationHeader(annual) {
      API.addEvaluationHeader(annual).then((res) => {
        if (res.success) {
          res.data[0].visibleIcon = 1;
          this.data = res.data;
          this.draftId = res.data[0].evaluateId;
          this.getNewData(this.data);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    // 评价体系详情
    getDetailData(id) {
      API.detailData(id).then((res) => {
        if (res.success) {
          res.data[0].visibleIcon = 1;
          this.draftId = res.data[0].evaluateId;
          this.data = res.data;
          this.getNewData(this.data);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
  },
  mounted() {
    if (this.config.type === "起草") {
      this.evaluationHeader(this.config.annual);
    } else if (this.config.type === "查看") {
      // 去掉操作列
      this.columns = columns.filter((item) => item.title !== "操作");
      this.getDetailData(this.config.id);
    } else if (this.config.type === "修改") {
      this.getDetailData(this.config.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.evaluationtitle {
  text-align: center;
  border: 1px solid #eee;
  background: #fafafa;
  padding: 1.5rem 0;
  font-weight: 700;
  font-size: 1.8rem;
}
.fs {
  font-size: 1.7rem;
  margin-right: 1rem;
}
.fs:hover {
  color: #ff4600;
  cursor: pointer;
}
/deep/ .ant-table-tbody:nth-child(1) {
  background: #fafafa;
}
</style>
